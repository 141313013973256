

export default {
  data() {
    return {
      showDialog: false
    }
  },
  mounted() {
    this.$nuxt.$on('showPopupAds', template => {
      this.generateIframe(template)
    })
  },
  methods: {
    generateIframe(template) {
      this.showDialog = true
      setTimeout(() => {
        let ifrm = document.getElementById('iframe_popup');
        ifrm = ifrm.contentWindow || ifrm.contentDocument.document || ifrm.contentDocument;
        ifrm.document.open();
        ifrm.document.write(template);
        ifrm.document.close();
      })
    }
  }
}
