
import thumnail from "../../assets/imgs/buycar/buy-car-background.webp"
import GetTime from "@/mixins/GetTime";
import UrlImageHandler from "@/mixins/UrlImageHandler";
import {SET_CURRENT_ID_NEW} from "@/utils/store.action";
import {ACTION_SET_CURRENT_CATEGORY} from "../../utils/store.action";

export default {
  name: "NewCategoryItem",
  mixins: [GetTime, UrlImageHandler],
  props: {
    item: Object
  },
  mounted() {
  },
  data() {
    return {
      thumnail: thumnail,
    }
  },
  computed: {
    categories() {
      if(this.item.category.length > 1) {
        const slug = this.$route.params.slug
        const categoryCurrent = this.item.category.find(category => category.slug === slug)
        const categoriesListTemp = this.item.category.filter(category => category.slug !== slug)
        categoriesListTemp.unshift(categoryCurrent)
        return categoriesListTemp.slice(0, 4)
      }
      return this.item.category
    }
  },
  methods: {
    formatDate(time) {
      return this.$moment(time * 1000).format('LL')
    },
    uriNewsCategories(item) {
      if (item?.url) {
        window.location.href = item.url
      } else {
        this.$store.dispatch(ACTION_SET_CURRENT_CATEGORY, item)
        this.$router.push({path: `/danh-muc-tin-tuc/${item.slug}`})
      }
    },
    onLoadNewDetail(item) {
      this.$store.dispatch(SET_CURRENT_ID_NEW, item._id)
      this.$router.push({path: `/tin-tuc/${item.slug}`})
    }
  }
}
