
import banner from "../../assets/imgs/banner/banner.webp"
import {
  ACTION_GET_NOTIFICATIONS,
  ACTION_MARK_READ_ALL,
  ACTION_MARK_READ_NOTIFICATION,
  ACTION_SET_COUNT_NOTIFY,
  ACTION_LAST_READ, ACTION_GET_LAST_READ, ACTION_SET_TOKEN_NOTIFY,

} from "@/utils/store.action";
import UrlImageHandler from "@/mixins/UrlImageHandler";
import ResizeHandler from "@/mixins/ResizeHandler";
import RudderStackHandler from "@/mixins/RudderStackHandler";

export default {
  name: "Notification",
  mixins: [UrlImageHandler, ResizeHandler, RudderStackHandler],
  data() {
    return {
      isShowNotify: false,
      banner: banner,
      notify: '',
      idToken: "",
      notifications: [],
      loading: false,
      count: 0,
      page: 0,
      total: 0,
      lastReadNotification: ''
    }
  },
  computed: {},
  async mounted() {
    if (process.client) {
      try {
        this.$fire.messaging.onMessage((payload) => {
        });
        navigator.serviceWorker.addEventListener('message', async (event) => {
          // await this.$store.dispatch(ACTION_SET_COUNT_NOTIFY, countTemp)
          this.count++
        },true);
        const res = await this.$store.dispatch(ACTION_GET_LAST_READ)
        if (res && res.notificationId) {
          this.lastReadNotification = res.notificationId
        }
        const {data} = await this.$store.dispatch(ACTION_GET_NOTIFICATIONS, {page: 1})
        this.count = this.countUnreadNotification(data)
      } catch (e) {
        // console.error("Error : ", e);
      }
    }
  },
  methods: {

    async showNotify() {
      this.page = 0
      this.count = 0
      this.notifications = []
      if(this.windowWidth < 640) {
        this.$router.push('/thong-bao')
        this.isShowNotify = false
      }
      else {
        this.isShowNotify = !this.isShowNotify
      }
    },
    closeNotification() {
      this.isShowNotify = false
    },
    seeAll() {
      this.$router.push('/thong-bao')
      this.isShowNotify = false
      this.count = 0
    },
    countUnreadNotification(data) {
      let count = 0
      for (const i of data) {
        if (i._id === this.lastReadNotification) {
          break
        }
        count++
      }
      return count
    },
    async infiniteHandler($state) {
      this.page++
      try {
        const {data} = await this.$store.dispatch(ACTION_GET_NOTIFICATIONS, {page: this.page})
        if (data.length) {
          data.forEach((item) => {
            this.notifications.push(item)
            $state.loaded();
          })
        } else {
          $state.complete();
        }
        if (this.page === 1 && data && data[0]) {
          const res = await this.$store.dispatch(ACTION_LAST_READ, {notificationId: data[0]._id})
        }
      } catch (e) {
        $state.complete();
      }
    },
    async handleClickNotify(notify) {
      if (!notify.read) {
        notify.read = 1
        const data = {
          signature: 1,
          notificationId: notify._id
        }
        const res = await this.$store.dispatch(ACTION_MARK_READ_NOTIFICATION, data)
      }
      const {direct} = notify
      if (direct) {
        window.location.href = `${window.location.origin}/${direct}`
        this.isShowNotify = false
      }
    },
    async markAllRead() {
      this.count = 0
      this.notifications.forEach(item => item.read = 1)
      await this.$store.dispatch(ACTION_MARK_READ_ALL)
    }
  }
}
